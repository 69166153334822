import React from 'react'
import ServicesFixed from './index.style'
import { Grid, Row, Col } from 'react-flexbox-grid'

import GroupMask from '../../static/images/fixed/mask-group.png'
import Exclamation from '../../static/images/fixed/exclamation-mark-icon.png'
import Thermo from '../../static/images/fixed/Icon-suhu-badan.png'
import Masker from '../../static/images/fixed/Icon-masker.png'
import Emoney from '../../static/images/fixed/Icon-emoney.png'

const FixedServices = () => {
  return (
    <ServicesFixed id="service-fixed">
      <Grid>
        <Row>
          <Col lg={12} sm={12} xs={12} md={12}>
            <Row>
              <Col lg={3} sm={3} xs={3} md={3}>
                <div className="fle-fix">
                  <img
                    className="group-mask"
                    src={GroupMask}
                    alt="halojasa/group_mask"
                    loading="lazy"
                  />
                </div>
              </Col>
              <Col lg={9} sm={9} xs={9} md={9}>
                <div className="des-fix">
                  <img
                    className="icon-ex"
                    src={Exclamation}
                    alt="halojasa/exclamation"
                    loading="lazy"
                  />
                  <span>
                    Seluruh layanan kami. Menerapkan standart protokol kesehatan
                    new normal.
                  </span>
                </div>
                <div className="bor-fix"></div>
                <div className="ic-fix">
                  <div className="ic-fix-w">
                    <img
                      className="icon-services"
                      src={Thermo}
                      alt="halojasa/thermo"
                      loading="lazy"
                    />
                    <span>
                      Laporan Suhu
                      <br />
                      Tubuh
                    </span>
                  </div>
                  <div className="ic-fix-w">
                    <img
                      className="icon-services"
                      src={Masker}
                      alt="halojasa/mask"
                      loading="lazy"
                    />
                    <span>
                      Penggunaan
                      <br />
                      Masker
                    </span>
                  </div>
                  <div className="ic-fix-w">
                    <img
                      className="icon-services"
                      src={Emoney}
                      alt="halojasa/e_money"
                      loading="lazy"
                    />
                    <span>
                      Pembayaran
                      <br />
                      E-Money
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </ServicesFixed>
  )
}

export default FixedServices
