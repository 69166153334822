import React, { useState, useEffect, useRef } from 'react'
import ServicesFixedResponsive from './index.style'
import { Grid, Row, Col } from 'react-flexbox-grid'

import ServiceGuarantee from '../../static/images/fixed/service-guarantee.webp'
import Exclamation from '../../static/images/fixed/exclamation-mark-icon.png'
import Guarantee from '../../static/images/fixed/guarantee.webp'
import Insurance from '../../static/images/fixed/insurance.webp'
import Professional from '../../static/images/fixed/professional.webp'
import CallCenter from '../../static/images/fixed/call-center.webp'
import BookingNow from '../../static/images/fixed/booking-now.webp'

const FixedServicesResponsive = () => {
  const prevScrollY = useRef(0)
  const [goingUp, setGoingUp] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false)
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true)
      }
      if (currentScrollY === 0) {
        setGoingUp(false)
      }
      prevScrollY.current = currentScrollY
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [goingUp])

  const onClickBookingButton = e => {
    e.preventDefault()
    // window.open(CustomerCare)
    window.open('https://link.halojasa.com')
  }

  return (
    <ServicesFixedResponsive id="service-fixed">
      <Grid>
        <Row>
          <Col lg={12} sm={12} xs={12} md={12}>
            <Row className="fle-fix">
              <Col lg={3} sm={3} xs={3} md={3}>
                <img
                  className="group-mask"
                  src={goingUp ? CallCenter : ServiceGuarantee}
                  alt="halojasa/massage"
                  loading="lazy"
                />
              </Col>
              <Col className="fl-fx" lg={9} sm={9} xs={9} md={9}>
                {!goingUp && (
                  <div>
                    <div className="des-fix">
                      <img
                        className="icon-ex"
                        src={Exclamation}
                        alt="halojasa/service"
                      />
                      <span>
                        Cek Detail Harga Layanan <br /> Fast booking khusus area
                        Jakarta
                      </span>
                    </div>
                    <button
                      onClick={onClickBookingButton}
                      type="button"
                      className="button-booking"
                    >
                      <img
                        className="image-booking"
                        src={BookingNow}
                        alt="halojasa/booking"
                        loading="lazy"
                      />
                      Booking Now
                    </button>
                  </div>
                )}
                {goingUp && (
                  <div>
                    <div className="des-fix">
                      <img
                        className="icon-ex"
                        src={Exclamation}
                        alt="halojasa/icon"
                        loading="lazy"
                      />
                      <span>Jaminan layanan pelanggan</span>
                    </div>
                    <div className="ic-fix">
                      <div className="ic-fix-w">
                        <img
                          className="icon-services"
                          src={Guarantee}
                          alt="halojasa/service_guarantee"
                          loading="lazy"
                        />
                        Bergaransi
                      </div>
                      <div className="ic-fix-w">
                        <img
                          className="icon-services"
                          src={Insurance}
                          alt="halojasa/service_insurance"
                          loading="lazy"
                        />
                        Berasuransi
                      </div>
                      <div className="ic-fix-w">
                        <img
                          className="icon-services"
                          src={Professional}
                          alt="halojasa/service_professional"
                          loading="lazy"
                        />
                        Profesional
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </ServicesFixedResponsive>
  )
}

export default FixedServicesResponsive
