/* eslint-disable */
import React from 'react'

import Star from '../../static/icons/star.svg'
import { CardTestimonialStyles } from './card-testimonial.styles'

const CardTestimonial = props => {
  const { avatarImage, name, job, desc } = props
  return (
    <CardTestimonialStyles>
      <div className="card-testimonial--header">
        <div className="card-testimonial--header--left">
          <div className="card-testimonial-profile">
            <img
              src={avatarImage}
              alt="halojasa/avatar-image"
              className="testimonial-avatar-image"
              loading="lazy"
            />
          </div>
        </div>
        <div className="card-testimonial--header--right">
          <div className="card-testimonial--header--name">
            <p className="name">{name}</p>
            <p className="role">{job}</p>
          </div>
          <div className="card-testimonial--header--rating">
            <img src={Star} alt="halojasa/star" loading="lazy" />
            <span>5</span>
          </div>
        </div>
      </div>
      <div className="card-testimonial--content">{desc}</div>
    </CardTestimonialStyles>
  )
}

export default CardTestimonial
