import styled from 'styled-components'

export default styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  .des-fix,
  .ic-fix {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-ex {
    max-width: 20px;
    margin-right: 5px;
  }

  .bor-fix {
    border: 1px solid #ccc;
  }

  .des-fix {
    box-sizing: border-box;
    padding: 8px 0px 8px 0px;

    span {
      margin-left: 5px;
      font-size: 19px;
      color: #929292;
    }
  }

  .ic-fix-w {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0px 12px 0px 12px;

    span {
      color: #717171;
    }
  }

  .icon-services {
    max-width: 60px;
  }
  .group-mask {
    max-width: 111px;
  }
`
